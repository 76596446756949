<app-modal heading="Map Settings">
  <div class="row mb-2">
    <div class="col-md-4 col-sm-12">Display unavailable drivers</div>
    <div class="col-md-8 col-sm-12">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="display-unavailable-drivers" id="checkbox-1">
        <label class="form-check-label" for="checkbox-1">
          Yes
        </label>
      </div>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="display-unavailable-drivers" id="checkbox-2" checked>
        <label class="form-check-label" for="checkbox-2">
          No
        </label>
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-4 col-sm-12">Traffic</div>
    <div class="col-md-8 col-sm-12">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="traffic" id="checkbox-3">
        <label class="form-check-label" for="checkbox-3">
          Yes
        </label>
      </div>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="traffic" id="checkbox-4" checked>
        <label class="form-check-label" for="checkbox-4">
          No
        </label>
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-4 col-sm-12">Labels</div>
    <div class="col-md-8 col-sm-12">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="labels" id="checkbox-5" checked>
        <label class="form-check-label" for="checkbox-5">
          Yes
        </label>
      </div>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="labels" id="checkbox-6">
        <label class="form-check-label" for="checkbox-6">
          No
        </label>
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-4 col-sm-12">Search location</div>
    <div class="col-md-8 col-sm-12">
      <input type="text" class="form-control" value="London, UK">
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-4 col-sm-12">Map refresh time</div>
    <div class="col-md-8 col-sm-12">
      <input type="number" class="form-control" value="21">
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-4 col-sm-12">Drivers refresh time</div>
    <div class="col-md-8 col-sm-12">
      <input type="number" class="form-control" value="20">
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-4 col-sm-12">Map type</div>
    <div class="col-md-8 col-sm-12">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="map-type" id="checkbox-7" checked>
        <label class="form-check-label" for="checkbox-7">
          Street map
        </label>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="map-type" id="checkbox-8">
        <label class="form-check-label" for="checkbox-8">
          Street map with terrain
        </label>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="map-type" id="checkbox-9">
        <label class="form-check-label" for="checkbox-9">
          Satellite imagery
        </label>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="map-type" id="checkbox-10">
        <label class="form-check-label" for="checkbox-10">
          Satellite imagery with street names
        </label>
      </div>

    </div>
  </div>
</app-modal>