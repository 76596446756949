<form action="#" method="POST" class="rr-form">
  <div class="row">
    <div class="form-group custom-input col-md-6 col-12">
      <input type="text" class="form-control" placeholder="National Insurance Number">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Bank Account Details">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Insurance">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Insurance Expiry Date">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Driving License">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Driving License Expiry Date">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="PCO License">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="PCO License Expiry Date">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="PHV License">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="PHV License Expiry Date">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Driver Income (%)">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Base Address">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <select class="custom-select">
        <option>Driver Activity Status</option>
        <option value="field-1">Unavailable</option>
        <option value="field-2">Available</option>
        <option value="field-2">On Break</option>
      </select>
    </div>


    <div class="form-group custom-upload col-12">
      <label for="general-upload">
        <span>Choose File</span> Upload Image
      </label>
      <input id="general-upload" type="file" class="form-control">
    </div>

    <div class="form-group mt-3">
      <button type="submit" class="btn custom-button mr-2">Add</button>
      <button type="button" class="btn btn-primary">Cancel</button>
    </div>

  </div>
</form>
