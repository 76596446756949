import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  @ViewChild('map', { static: false }) gmap: ElementRef;

  map: google.maps.Map;

  lat       = 40.730610;
  lng       = -73.935242;

  name      = "";
  address   = "London, United Kingdom";
  radius    = 80000;
  order     = 0;

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8,
    fullscreenControl: false,
    mapTypeControl: false,
    zoomControl: false,
  };

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.setTitle('Zones / Create');
  }

  ngAfterViewInit() {

    this.mapInitializer();

  }

  mapInitializer() {
    this.map = new google.maps.Map(
      this.gmap.nativeElement, 
      this.mapOptions
    );

    var marker = new google.maps.Marker({
      map: this.map,
      position: new google.maps.LatLng(this.lat, this.lng),
      title: 'Some location'
    });

    var circle = new google.maps.Circle({
      map: this.map,
      radius: 80000,
      fillColor: '#a43792',
      strokeColor: "#9b348a"
    });

    circle.bindTo('center', marker, 'position');
  }

}
