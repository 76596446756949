import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss']
})
export class StylesComponent implements OnInit {

  webCustomCSS: boolean = false ;
  mobileCustomCSS: boolean = false ;

  webCustomCSSToggle() {
    this.webCustomCSS = ! this.webCustomCSS;
  }

  mobileCustomCSSToggle() {
    this.mobileCustomCSS = ! this.mobileCustomCSS;
  }

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setTitle('Settings / Styles')
  }

}
