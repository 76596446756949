import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { CommentsComponent } from './feedback/comments/comments.component';
import { LayoutComponent } from './layout/layout.component';
import { AddNewComponent as UsersAddNewComponent } from './users/add-new/add-new.component';
import { CompletedComponent } from './bookings/completed/completed.component';
import { CancelledComponent } from './bookings/cancelled/cancelled.component';
import { LatestComponent } from './bookings/latest/latest.component';
import { NextTwentyFourHoursComponent } from './bookings/next-twenty-four-hours/next-twenty-four-hours.component';
import { TrashComponent as BookingsTrashComponent } from './bookings/trash/trash.component';
import { DriversComponent } from './users/drivers/drivers.component';
import { CustomersComponent } from './users/customers/customers.component';
import { AdminsComponent } from './users/admins/admins.component';
import { ProfileComponent } from './profile/profile.component';
import { ZonesComponent } from './settings/zones/zones.component';
import { VehiclesComponent } from './settings/vehicles/vehicles.component';
import { UsersComponent as SettingsUsersComponent } from './settings/users/users.component';
import { StylesComponent } from './settings/styles/styles.component';
import { ServicesComponent } from './settings/services/services.component';
import { InvoicesComponent } from './settings/invoices/invoices.component';
import { DepositPaymentComponent } from './settings/deposit-payment/deposit-payment.component';
import { ComplaintsComponent } from './feedback/complaints/complaints.component';
import { LostAndFoundComponent } from './feedback/lost-and-found/lost-and-found.component';
import { DriverComponent } from './reports/driver/driver.component';
import { PaymentComponent } from './reports/payment/payment.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CreateComponent as BookingsCreateComponent } from './bookings/create/create.component';
import { AllComponent } from './bookings/all/all.component';
import { ReportsComponent } from './reports/reports.component';
import { TrashComponent as ReportsTrashComponent} from './reports/trash/trash.component';
import { CustomerComponent } from './users/add-new/customer/customer.component';
import { CreateComponent as ZonesCreateComponent } from './settings/zones/create/create.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'getting-started', component: GettingStartedComponent },
      { path: 'documentation', component: DocumentationComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'calendar', component: CalendarComponent },
      {
        path: 'feedback',
        children: [
          { path: '', redirectTo: 'create', pathMatch: 'full' },
          { path: 'create', component: FeedbackComponent },
          { path: 'comments', component: CommentsComponent },
          { path: 'complaints', component: ComplaintsComponent },
          { path: 'lost-and-found', component: LostAndFoundComponent },
        ]
      },
      {
        path: 'reports',
        children: [
          { path: '', redirectTo: 'saved', pathMatch: 'full' },
          { path: 'saved', component: ReportsComponent },
          { path: 'trash', component: ReportsTrashComponent },
          { path: 'driver', component: DriverComponent },
          { path: 'payment', component: PaymentComponent },
        ]
      },
      {
        path: 'users',
        children: [
          { path: '', redirectTo: 'create', pathMatch: 'full' },
          { path: 'create', component: UsersAddNewComponent },
          { path: 'drivers', component: DriversComponent },
          { path: 'customers', component: CustomersComponent },
          { path: 'customers/create', component: CustomerComponent },
          { path: 'admins', component: AdminsComponent },
        ]
      },
      {
        path: 'bookings',
        children: [
          { path: '', redirectTo: 'all', pathMatch: 'full' },
          { path: 'all', component: AllComponent },
          { path: 'trash', component: BookingsTrashComponent },
          { path: 'completed', component: CompletedComponent },
          { path: 'cancelled', component: CancelledComponent },
          { path: 'latest', component: LatestComponent },
          { path: 'next-24-hours', component: NextTwentyFourHoursComponent },
          { path: 'create', component: BookingsCreateComponent },
        ]
      },
      {
        path: 'settings',
        children: [
          { path: '', redirectTo: 'users', pathMatch: 'full' },
          { 
            path: 'zones', 
            children: [
              { path: '', component: ZonesComponent },
              { path: 'create', component: ZonesCreateComponent },
            ]
          },
          { path: 'vehicles', component: VehiclesComponent },
          { path: 'users', component: SettingsUsersComponent },
          { path: 'styles', component: StylesComponent },
          { path: 'services', component: ServicesComponent },
          { path: 'invoices', component: InvoicesComponent },          
          { path: 'deposit-payment', component: DepositPaymentComponent },
        ]
      },

      /* Root path redirection */
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    ]
  },

  { path: 'login', component: LoginComponent },

  /* Not found redirection */
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }
