import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

declare var $: any;

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit, AfterViewInit {

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setTitle('Reports / Driver');
  }

  ngAfterViewInit() {
    $('.datepicker').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      timePicker: true,
      autoUpdateInput: true,
    })
  }

}
