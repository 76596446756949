<div class="app d-flex">
  <div *ngIf="menuToggle" (click)="toggleMenu()" class="backdrop"></div>
  <aside class="sidebar" *ngIf="menuToggle">
    <div class="logo">
      <a href="#">
        <img src="assets/images/logo.png" alt="Logo Reliable Ride">
      </a>
    </div>

    <nav class="navigation">
      <ul>
        <li>
          <a routerLink="/dashboard" routerLinkActive="active">
            <img src="assets/images/1.png" alt="Text">
          </a>
        </li>
        <li>
          <a routerLink="/bookings" routerLinkActive="active">
            <img src="assets/images/2.png" alt="Text">
          </a>

          <ul class="sub-menu">
            <li>
              <a routerLink="/bookings/next-24-hours" routerLinkActive="active">Next 24 Hours</a>
            </li>
            <li>
              <a routerLink="/bookings/latest" routerLinkActive="active">Latest</a>
            </li>
            <li>
              <a routerLink="/bookings/completed" routerLinkActive="active">Completed</a>
            </li>
            <li>
              <a routerLink="/bookings/cancelled" routerLinkActive="active">Cancelled</a>
            </li>
            <li>
              <a routerLink="/bookings/all" routerLinkActive="active">All</a>
            </li>
            <li>
              <a routerLink="/bookings/trash" routerLinkActive="active">Trash</a>
            </li>
            <li>
              <a routerLink="/calendar" routerLinkActive="active">Calendar</a>
            </li>
            <li>
              <a routerLink="/bookings/create" routerLinkActive="active">Add New</a>
            </li>
          </ul>
        </li>
        <li>
          <a routerLink="/reports" routerLinkActive="active">
            <img src="assets/images/3.png" alt="Text">
          </a>

          <ul class="sub-menu">
            <li>
              <a routerLink="/reports/driver" routerLinkActive="active">Driver</a>
            </li>
            <li>
              <a routerLink="/reports/payment" routerLinkActive="active">Payment</a>
            </li>
            <li>
              <a routerLink="/reports/trash" routerLinkActive="active">Trashed</a>
            </li>
            <li>
              <a routerLink="/reports/saved" routerLinkActive="active">Saved</a>
            </li>
          </ul>
        </li>
        <li>
          <a routerLink="/users" routerLinkActive="active">
            <img src="assets/images/4.png" alt="Text">
          </a>

          <ul class="sub-menu">
            <li>
              <a routerLink="/users/customers" routerLinkActive="active">Customers</a>
            </li>
            <li>
              <a routerLink="/users/drivers" routerLinkActive="active">Drivers</a>
            </li>
            <li>
              <a routerLink="/users/admins" routerLinkActive="active">Admins</a>
            </li>
            <li>
              <a routerLink="/users/create" routerLinkActive="active">Add New</a>
            </li>
          </ul>          
        </li>
        <li>
          <a routerLink="/feedback" routerLinkActive="active">
            <img src="assets/images/5.png" alt="Text">
          </a>

          <ul class="sub-menu">
            <li>
              <a routerLink="/feedback/comments" routerLinkActive="active">Comments</a>
            </li>
            <li>
              <a routerLink="/feedback/complaints" routerLinkActive="active">Complaints</a>
            </li>
            <li>
              <a routerLink="/feedback/lost-and-found" routerLinkActive="active">Lost & Found</a>
            </li>
            <li>
              <a routerLink="/feedback/create" routerLinkActive="active">Add New</a>
            </li>
          </ul>
        </li>
        <li>
          <a routerLink="/settings" routerLinkActive="active">
            <img src="assets/images/6.png" alt="Text">
          </a>

          <ul class="sub-menu">
            <li>
              <a routerLink="/settings/invoices" routerLinkActive="active">Invoices</a>
            </li>
            <li>
              <a routerLink="/settings/services" routerLinkActive="active">Services</a>
            </li>
            <li>
              <a routerLink="/settings/styles" routerLinkActive="active">Styles</a>
            </li>
            <li>
              <a routerLink="/settings/users" routerLinkActive="active">Users</a>
            </li>
            <li>
              <a routerLink="/settings/vehicles" routerLinkActive="active">Vehicles</a>
            </li>
            <li>
              <a routerLink="/settings/zones" routerLinkActive="active">Zones</a>
            </li>
            <li>
              <a routerLink="/settings/deposit-payment" routerLinkActive="active">Deposit Payment</a>
            </li>
          </ul>
        </li>
        <li>
          <a routerLink="/documentation" routerLinkActive="active">
            <img src="assets/images/7.png" alt="Text">
          </a>
        </li>
        <li>
          <a routerLink="/profile" routerLinkActive="active">
            <img src="assets/images/8.png" alt="Text">
          </a>
        </li>
        <li>
          <a routerLink="/login" routerLinkActive="active">
            <img src="assets/images/9.png" alt="Text">
          </a>
        </li>
      </ul>
    </nav>
  </aside>

  <div class="full-width">
    <header class="d-flex align-items-center">
      <div class="container-fluid d-flex justify-content-between">

        <div class="left-side d-flex align-items-center">
          <button class="btn mr-2" (click)="toggleMenu()" *ngIf="mobileMenu">
            <i class="fas fa-bars"></i>
          </button>

          <button class="btn" (click)="goBack()">
            <i class="fas fa-long-arrow-alt-left"></i>
          </button>
          
          <span class="title">{{ title }}</span>

          <span class="sub-title" *ngIf="subTitle">
            <i class="fas fa-chevron-right mx-2"></i> {{ subTitle }}
          </span>
        </div>

        <div class="right-side d-flex align-items-center">
          <div class="btn-group">
            <button class="btn notification" data-toggle="dropdown">
              <div class="dot"></div>
              <i class="far fa-bell"></i>
            </button>
            <div class="dropdown-menu">
              <h6 class="dropdown-header">Recent Notifications</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
            </div>
          </div>

          <img class="avatar mr-2 d-none d-sm-block" src="assets/images/avatar.jpg" alt="Avatar">

          <div class="btn-group profile-menu d-none d-sm-block">
            <button type="button" class="btn">Thomas</button>
            <button type="button" class="btn dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
        </div>
      </div>
    </header>
  
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
