<div class="container-fluid">
  <div class="col-12">
    <div class="box documentation">
      <h1>Getting Started</h1>
      <p class="mb-5">Welcome to EasyTaxiOffice Service.</p>

      <p class="primary-text">Here's some useful information on how to test the software.</p>

      <ul>
        <li>
          Add a test booking via <span>Dispatch tab</span>.
        </li>
        <li>
          Test <span>Web Booking</span> to see how customer booking journey looks like.
        </li>
        <li>
          Dispatch job to a driver.
        </li>
        <li>
          Test how to setup and use Driver App, create driver account. Read step by step guide <span>Settings -> Mobile Apps</span>.
        </li>
        <li>
          Add company details and <span>preview</span> all ongoing communication between customer, driver and operator. Once set, all three (email, sms, push) ways of communication are fully automatic.
        </li>
        <li>
          <span>Create customer account</span> and learn what tools are available for your customer.
        </li>
        <li>
          Set your own pricing in <span>Settings -> Pricing</span>.
        </li>
        <li>
          Add booking and customer widgets to your website. Read step by step guide <span>Settings -> Web Widgets</span>.
        </li>
        <li>
          To learn more about the software setup or its features please visit our online <span>documentation</span>.
        </li>
      </ul>
    </div>
  </div>
</div>
