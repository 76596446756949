<form action="#" method="POST" class="rr-form">
  <div class="row">
    <div class="form-group custom-input col-12">
      <input type="text" class="form-control" placeholder="Name">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Username">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="email" class="form-control" placeholder="Email">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="password" class="form-control" placeholder="Password">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="password" class="form-control" placeholder="Confirm Password">
    </div>

    <div class="form-group custom-upload col-12">
      <label for="general-upload">
        <span>Choose File</span> Upload Image
      </label>
      <input id="general-upload" type="file" class="form-control">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <select class="custom-select">
        <option selected>Role</option>
        <option value="field-1">Field 1</option>
        <option value="field-2">Field 2</option>
      </select>
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <select class="custom-select">
        <option selected>Status</option>
        <option value="field-1">Field 1</option>
        <option value="field-2">Field 2</option>
      </select>
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <select class="custom-select">
        <option selected>Language</option>
        <option value="field-1">Field 1</option>
        <option value="field-2">Field 2</option>
      </select>
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <select class="custom-select">
        <option selected>Timezone</option>
        <option value="field-1">Field 1</option>
        <option value="field-2">Field 2</option>
      </select>
    </div>

    <div class="form-group mt-3">
      <button type="submit" class="btn custom-button mr-2">Add</button>
      <button type="button" class="btn btn-primary">Cancel</button>
    </div>

  </div>
</form>
