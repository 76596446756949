import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  comments = [];

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.setTitle('Feedback / Comments');
  }

}
