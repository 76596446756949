<div class="container-fluid">
  <div class="row">
    <section class="col-md-6 col-sm-12 mb-5">
      <div class="box">
        <bookings-add-new></bookings-add-new>
      </div>
    </section>
    <section class="col-md-6 col-sm-12 mb-5">

      <div class="box" #map id="map"></div>

      <div class="map-controls">
        <input type="text" class="form-control" placeholder="Find Driver">

        <button class="btn ml-4" (click)="openSettings()">
          <i class="fas fa-cogs"></i>
        </button>
        <button class="btn" (click)="expandMap()">
          <i class="fas fa-expand"></i>
        </button>
      </div>

      <div class="map-zoom-controls">
        <button class="btn" (click)="zoomIn()">
          <i class="fas fa-plus"></i>
        </button>
        <button class="btn" (click)="zoomOut()">
          <i class="fas fa-minus"></i>
        </button>
      </div>

    </section>
  </div>
</div>

<div class="container-fluid mb-5">
  <app-bookings></app-bookings>
</div>
