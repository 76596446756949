<div class="container-fluid">
  <div class="col-12">
    <div class="box documentation">
      <h2>Heading 1</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio ipsa minus excepturi accusamus maiores, magni neque architecto quos tempora? Quaerat fugiat impedit enim neque magnam officiis numquam reprehenderit. Suscipit, cum.
      </p>

      <h2>Heading 2</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio ipsa minus excepturi accusamus maiores, magni neque architecto quos tempora? Quaerat fugiat impedit enim neque magnam officiis numquam reprehenderit. Suscipit, cum.
      </p>

      <h2>Heading 3</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio ipsa minus excepturi accusamus maiores, magni neque architecto quos tempora? Quaerat fugiat impedit enim neque magnam officiis numquam reprehenderit. Suscipit, cum.
      </p>        
    </div>
  </div>
</div>
