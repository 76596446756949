<div class="container-fluid mb-5">
  <div class="box shadow">

    <div class="filters mb-5">
      <div class="actions">
        <button class="btn">
          <i class="far fa-eye"></i>
        </button>
        <button class="btn">
          <i class="far fa-sync"></i>
        </button>
        <button class="btn">
          <i class="far fa-redo-alt"></i>
        </button>

        <select class="custom-select">
          <option>Export</option>
          <option>CSV</option>
          <option>PDF</option>
        </select>

        <a class="btn add-new" routerLink="/bookings/create">
          <i class="far fa-plus mr-2"></i>
          Add New 
        </a>
      </div>

      <div class="search-box">
        <i class="far fa-search"></i>
        <input type="text" placeholder="Search">
      </div>
    </div>

    <div class="table-responsive">
      <table class="table custom-table">
        <thead>
          <tr>
            <th scope="col" class="no-width">
              Action
            </th>
            <th scope="col" class="no-width">
              Info
            </th>
            <th scope="col">
              Date & Time
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Ref ID
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Status
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Payments
            </th>
            <th scope="col">
              Driver
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Vehicles
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Total
            </th>
            <th scope="col">
              Driver Income
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Passenger Charge
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="actions">
                <button class="btn">
                  <i class="far fa-eye"></i>
                </button>
                <button class="btn">
                  <i class="fas fa-caret-down"></i>
                </button>
              </div>
            </td>
            <td>
              <button class="btn">
                <i class="fas fa-info-circle"></i>
              </button>
            </td>
            <td>11/12/2020 12:00</td>
            <td>ZICKNESS</td>
            <td>
              <button class="btn">
                <span class="badge badge-red">Trash</span>
                <i class="far fa-edit ml-2"></i>
              </button>
            </td>
            <td>
              $24.00 (Unpaid) 
              <button class="btn"><i class="far fa-eye"></i></button>
            </td>
            <td>
              Assign Driver 
              <button class="btn"><i class="fas fa-plus"></i></button>
            </td>
            <td></td>
            <td>$24.00</td>
            <td>
              $10.00 
              <button class="btn"><i class="far fa-edit"></i></button>
            </td>
            <td>
              $10.00 
              <button class="btn"><i class="far fa-edit"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
