<div class="container-fluid mb-5">
  <div class="box shadow">

    <div class="mb-4 filters">
      <div class="actions">
        <button 
          class="btn" 
          [ngClass]="{ 'active' : formType === 'general' }"
          (click)="formType = 'general'"
        >
          General
        </button>
        <button 
          class="btn" 
          [ngClass]="{ 'active' : formType === 'personal' }"
          (click)="formType = 'personal'"
        >
          Personal
        </button>
        <ng-container *ngIf="role === 'driver'">
          <button 
            class="btn" 
            [ngClass]="{ 'active' : formType === 'other' }"
            (click)="formType = 'other'"
          >
            Other
          </button>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="formType === 'general'">
      <app-general></app-general>
    </ng-container>

    <ng-container *ngIf="formType === 'personal'">
      <app-personal></app-personal>
    </ng-container>

    <ng-container *ngIf="formType === 'other' && role === 'driver'">
      <app-other></app-other>
    </ng-container>

  </div>
</div>