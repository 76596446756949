<div class="container-fluid mb-5">
  <div class="box shadow">

    <div class="row mb-4">
      <div class="col-md-3 col-sm-12">
        <select class="custom-select">
          <option value="">Service Type</option>
          <option value="">Unassigned</option>
        </select>
      </div>
      <div class="col-md-3 col-sm-12">
        <select class="custom-select">
          <option value="">Status</option>
          <option value="pending">Confirmed</option>
          <option value="requested">Unconfirmed</option>
          <option value="quote">Request Quote</option>
          <option value="assigned">Assigned</option>
          <option value="accepted">Job Accepted</option>
          <option value="rejected">Job Rejected</option>
          <option value="onroute">On Route</option>
          <option value="arrived">Arrived</option>
          <option value="onboard">On Board</option>
          <option value="completed">Completed</option>
          <option value="canceled">Cancelled</option>
          <option value="unfinished">Driver Cancelled</option>
          <option value="incomplete">Incomplete</option>
        </select>
      </div>
      <div class="col-md-3 col-sm-12">
        <select class="custom-select">
          <option value="">Driver</option>
          <option value="">Option</option>
        </select>
      </div>
      <div class="col-md-3 col-sm-12">
        <select class="custom-select">
          <option value="">Journey Date</option>
          <option value="">Created Date</option>
        </select>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-6 col-sm-12">
        <input type="text" class="form-control datepicker" placeholder="From">
      </div>
      <div class="col-md-6 col-sm-12">
        <input type="text" class="form-control datepicker" placeholder="To">
      </div>

    </div>

    <div>
      <button type="submit" class="btn btn-border mr-2">Generate</button>
      <button type="button" class="btn btn-primary">Reset</button>
    </div>

  </div>
</div>
