<form action="#" method="POST" class="rr-form">
  <div class="row">
    <div class="form-group custom-input col-md-4 col-sm-12">
      <input type="text" class="form-control" placeholder="Title">
    </div>

    <div class="form-group custom-input col-md-4 col-sm-12">
      <input type="text" class="form-control" placeholder="First Name">
    </div>

    <div class="form-group custom-input col-md-4 col-sm-12">
      <input type="text" class="form-control" placeholder="Last Name">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Date of birth">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Mobile Number">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Telephone Number">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Fax Number">
    </div>

    <div class="form-group custom-input col-12">
      <input type="text" class="form-control" placeholder="Emergency Number">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Country">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Province">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="City">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Postal Code">
    </div>

    <div class="form-group custom-input col-12">
      <select class="custom-select">
        <option selected>Profile Type</option>
        <option value="field-1">Field 1</option>
        <option value="field-2">Field 2</option>
      </select>
    </div>

    <div class="form-group custom-input col-12">
      <input type="text" class="form-control" placeholder="Notes">
    </div>

    <div class="form-group mt-3">
      <button type="submit" class="btn custom-button mr-2">Add</button>
      <button type="button" class="btn btn-primary">Cancel</button>
    </div>

  </div>
</form>
