import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { AppService } from '../app.service';

import { BsModalService } from 'ngx-bootstrap/modal';
import { MapSettingsComponent } from '../modals/map-settings/map-settings.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild('map', { static: false }) gmap: ElementRef;

  map: google.maps.Map;

  lat = 40.730610;
  lng = -73.935242;

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8,
    fullscreenControl: false,
    mapTypeControl: false,
    zoomControl: false,
  };

  constructor(
    private appService: AppService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.appService.setTitle('Dashboard');
  }

  ngAfterViewInit() {

    this.mapInitializer();

  }

  mapInitializer() {
    this.map = new google.maps.Map(
      this.gmap.nativeElement, 
      this.mapOptions
    );
  }

  openSettings() {
    this.modalService.show(MapSettingsComponent);
  }

  expandMap() {
    this.map.getDiv().requestFullscreen();
  }

  zoomIn() {
    this.map.setZoom(this.map.getZoom() + 1);
  }

  zoomOut() {
    this.map.setZoom(this.map.getZoom() - 1);
  }

}
