<div class="container-fluid mb-5">
  <div class="box shadow">

    <form action="#" method="POST" class="rr-form">
      <div class="row">
        <div class="form-group custom-input col-12">
          <input type="text" class="form-control" placeholder="Title">
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12">
          <input type="text" class="form-control" placeholder="First Name">
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12">
          <input type="text" class="form-control" placeholder="Last Name">
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12">
          <input type="password" class="form-control" placeholder="Password">
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12">
          <input type="password" class="form-control" placeholder="Confirm Password">
        </div>

        <div class="form-group custom-input col-12">
          <textarea rows="4" class="form-control" placeholder="Description"></textarea>
        </div>

        <div class="form-group custom-input col-md-4 col-sm-12">
          <input type="text" class="form-control" placeholder="Mobile Number">
        </div>

        <div class="form-group custom-input col-md-4 col-sm-12">
          <input type="text" class="form-control" placeholder="Telephone Number">
        </div>

        <div class="form-group custom-input col-md-4 col-sm-12">
          <input type="text" class="form-control" placeholder="Emergency Number">
        </div>

        <div class="form-group custom-input col-12">
          <textarea rows="2" class="form-control" placeholder="Address"></textarea>
        </div>

        <div class="form-group custom-input col-md-4 col-sm-12">
          <input type="text" class="form-control" placeholder="City">
        </div>

        <div class="form-group custom-input col-md-4 col-sm-12">
          <input type="text" class="form-control" placeholder="County">
        </div>

        <div class="form-group custom-input col-md-4 col-sm-12">
          <input type="text" class="form-control" placeholder="Country">
        </div>

        <div class="form-group custom-input col-12 mt-4">
          <div class="checkbox mb-4">
            <input type="checkbox" id="checkbox-1">
            <label for="checkbox-1">
              Company Account
            </label>
          </div>
        </div>

        <div class="form-group custom-upload col-12">
          <label for="general-upload">
            <span>Choose File</span> Upload Avatar
          </label>
          <input id="general-upload" type="file" class="form-control">
        </div>


        <div class="form-group custom-input col-md-4 col-sm-12">
          <input type="text" class="form-control" placeholder="IP">
        </div>

        <div class="form-group custom-input col-md-4 col-sm-12">
          <input type="text" readonly class="form-control" placeholder="Last Visit">
        </div>

        <div class="form-group custom-input col-md-4 col-sm-12">
          <input type="text" readonly class="form-control" placeholder="Created Date">
        </div>

        <div class="form-group custom-input col-md-2 col-sm-12 mt-4">
          <div class="checkbox mb-4">
            <input type="checkbox" id="checkbox-2" checked>
            <label for="checkbox-2">
              Verified
            </label>
          </div>
        </div>

        <div class="form-group custom-input col-md-2 col-sm-12 mt-4">
          <div class="checkbox mb-4">
            <input type="checkbox" id="checkbox-3" checked>
            <label for="checkbox-3">
              Active
            </label>
          </div>
        </div>

        <div class="form-group col-12 mt-3">
          <button type="submit" class="btn custom-button mr-2">Add</button>
          <button type="button" class="btn btn-primary">Cancel</button>
        </div>

      </div>
    </form>

  </div>
</div>
