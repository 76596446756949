<div class="container-fluid mb-5">
  <div class="box shadow">

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-1" checked>
      <label for="checkbox-1">
        Enable Invoices
      </label>
    </div>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-2"checked>
      <label for="checkbox-2">
        Display Journey Details
      </label>
    </div>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-3">
      <label for="checkbox-3">
        Display Company logo
      </label>
    </div>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-3">
      <label for="checkbox-3">
        Display Payment History
      </label>
    </div>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-3">
      <label for="checkbox-3">
        Display Custom Field
      </label>
    </div>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-3" checked>
      <label for="checkbox-3">
        Display Customer Company Number
      </label>
    </div>

    <div class="checkbox mb-4">
      <input type="checkbox" id="checkbox-3">
      <label for="checkbox-3">
        Display Customer Company Tax Number
      </label>
    </div>

    <div class="row mb-4">
      <div class="col-md-6 col-sm-12">
        <textarea rows="6" class="form-control" placeholder="Additional Information"></textarea>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-6 col-sm-12">
        <textarea rows="6" class="form-control" placeholder="Company Information e.g. ( Name, Address )"></textarea>
      </div>
    </div>

    <button type="button" class="btn btn-lg btn-primary">Save</button>

  </div>
</div>