import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppService } from 'src/app/app.service';
import { AddNewComponent } from 'src/app/modals/vehicles/add-new/add-new.component';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {

  vehicles = [];

  constructor(
    private appService: AppService, 
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.appService.setTitle('Settings / Vehicles');
  }

  openAddNew() {
    this.modalService.show(AddNewComponent);
  }

}
