<div class="container-fluid mb-5">
  <div class="box shadow">

    <div class="mb-5 filters">
      <div class="actions">
        <button class="btn active">General</button>
        <button class="btn">Personal</button>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-4 col-sm-12">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Name">
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Percent %">
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Value">
        </div>
      </div>
      <div class="col-12">
        <button type="button" class="btn btn-white">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Remaining Amount Will Be Paid">
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Select Value By Default">
        </div>
      </div>
    </div>

    <div class="checkbox mb-4">
      <input type="checkbox" id="checkbox-6">
      <label for="checkbox-6">
        Active Fixed Deposit
      </label>
    </div>

    <button type="button" class="btn btn-lg btn-primary">Save</button>

  </div>
</div>
