import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { LayoutComponent } from './layout/layout.component';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';

import { FeedbackComponent } from './feedback/feedback.component';
import { CommentsComponent } from './feedback/comments/comments.component';

import { UsersComponent } from './users/users.component';
import { AddNewComponent as UsersAddNewComponent } from './users/add-new/add-new.component';
import { PersonalComponent } from './users/add-new/personal/personal.component';
import { GeneralComponent } from './users/add-new/general/general.component';

import { BookingsComponent } from './bookings/bookings.component';
import { AddNewComponent as BookingsAddNewComponent } from './bookings/add-new/add-new.component';

import { AppService } from './app.service';
import { CancelledComponent } from './bookings/cancelled/cancelled.component';
import { CompletedComponent } from './bookings/completed/completed.component';
import { LatestComponent } from './bookings/latest/latest.component';
import { NextTwentyFourHoursComponent } from './bookings/next-twenty-four-hours/next-twenty-four-hours.component';
import { TrashComponent as BookingsTrashComponent } from './bookings/trash/trash.component';
import { DriversComponent } from './users/drivers/drivers.component';
import { CustomersComponent } from './users/customers/customers.component';
import { AdminsComponent } from './users/admins/admins.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { ZonesComponent } from './settings/zones/zones.component';
import { VehiclesComponent } from './settings/vehicles/vehicles.component';
import { StylesComponent } from './settings/styles/styles.component';
import { ServicesComponent } from './settings/services/services.component';
import { InvoicesComponent } from './settings/invoices/invoices.component';
import { DepositPaymentComponent } from './settings/deposit-payment/deposit-payment.component';
import { ComplaintsComponent } from './feedback/complaints/complaints.component';
import { LostAndFoundComponent } from './feedback/lost-and-found/lost-and-found.component';
import { ReportsComponent } from './reports/reports.component';
import { DriverComponent } from './reports/driver/driver.component';
import { PaymentComponent } from './reports/payment/payment.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CreateComponent } from './bookings/create/create.component';
import { AllComponent } from './bookings/all/all.component';
import { TrashComponent as ReportsTrashComponent } from './reports/trash/trash.component';
import { OtherComponent } from './users/add-new/other/other.component';
import { CustomerComponent } from './users/add-new/customer/customer.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { MapSettingsComponent } from './modals/map-settings/map-settings.component';
import { ModalComponent } from './modals/modal/modal.component';
import { AddNewComponent } from './modals/vehicles/add-new/add-new.component';

@NgModule({
  declarations: [
    LayoutComponent,

    AppComponent,
    LoginComponent,
    DashboardComponent,
    GettingStartedComponent,
    DocumentationComponent,

    FeedbackComponent,
    CommentsComponent,

    UsersComponent,
    UsersAddNewComponent,
    PersonalComponent,
    GeneralComponent,
    
    BookingsComponent,
    BookingsAddNewComponent,
    CancelledComponent,
    CompletedComponent,
    LatestComponent,
    NextTwentyFourHoursComponent,
    BookingsTrashComponent,
    DriversComponent,
    CustomersComponent,
    AdminsComponent,
    ProfileComponent,
    SettingsComponent,
    ZonesComponent,
    VehiclesComponent,
    StylesComponent,
    ServicesComponent,
    InvoicesComponent,
    DepositPaymentComponent,
    ComplaintsComponent,
    LostAndFoundComponent,
    ReportsComponent,
    DriverComponent,
    ReportsTrashComponent,
    PaymentComponent,
    CalendarComponent,
    CreateComponent,
    AllComponent,
    OtherComponent,
    CustomerComponent,
    MapSettingsComponent,
    ModalComponent,
    AddNewComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ModalModule.forRoot()
  ],
  providers: [AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
