<div class="container-fluid mb-5">
  <div class="box shadow">

    <div class="filters mb-5">
      <div class="actions">
        <button class="btn">
          <i class="far fa-eye"></i>
        </button>
        <button class="btn">
          <i class="far fa-sync"></i>
        </button>
        <button class="btn">
          <i class="far fa-redo-alt"></i>
        </button>
        <a class="btn add-new" routerLink="/feedback/create">
          <i class="far fa-plus mr-2"></i>
          Add New 
        </a>
      </div>

      <div class="search-box">
        <i class="far fa-search"></i>
        <input type="text">
      </div>
    </div>

    <div *ngIf="lostAndFounds.length" class="table-responsive">
      <table class="table custom-table">
        <thead>
          <tr>
            <th scope="col" class="no-width">
              Action
            </th>
            <th scope="col">
              Name
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Type
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Parameters
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Featured
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Status
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
    </div>

    <div *ngIf="lostAndFounds.length === 0">
      <p class="empty">No Data Available</p>
    </div>

  </div>
</div>
