import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  reports = [];

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setTitle('Reports / Saved')
  }

}
