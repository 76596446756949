import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.scss']
})
export class ComplaintsComponent implements OnInit {

  complaints = []

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setTitle('Feedback / Complaints');
  }

}
