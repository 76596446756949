import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-lost-and-found',
  templateUrl: './lost-and-found.component.html',
  styleUrls: ['./lost-and-found.component.scss']
})
export class LostAndFoundComponent implements OnInit {

  lostAndFounds = []

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setTitle('Feedback / Lost & Found');
  }

}
