import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from '../app.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  menuToggle = true;

  mobileMenu = false;

  title: string;

  subTitle: string;

  constructor(
    private appService: AppService, 
    private cd: ChangeDetectorRef,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.appService.title$.subscribe(title => {

      let parts = title.split('/');

      this.title = parts[0]?.trim();
      this.subTitle = parts[1]?.trim();

      this.cd.detectChanges();
    })

    if (window.innerWidth <= 991) {
      this.menuToggle = false;
      this.mobileMenu = true;
    }


    window.onresize = () => {
      this.menuToggle = window.innerWidth >= 991
      this.mobileMenu = window.innerWidth <= 991
    };
  }

  toggleMenu() {
    this.menuToggle = ! this.menuToggle;
  }

  goBack() {
    this.location.back();
  }

}
