<app-modal heading="Add New">
  <div class="row mb-2">
    <div class="col-12 mb-2">
      <input type="text" class="form-control" placeholder="Name">
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <select class="custom-select">
        <option value="">Assign Driver</option>
        <option value="test">Test Driver</option>
      </select>
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <select class="custom-select">
        <option value="">Vehicle Type</option>
        <option value="">8 Seater</option>
        <option value="">Estate</option>
        <option value="">Executive</option>
        <option value="">MPV</option>
        <option value="">Saloon</option>
      </select>
    </div>

    <div class="col-md-4 col-sm-12 d-flex align-items-center mb-2">
      <label for="photo" class="pl-1 m-0">Upload Photo</label>
    </div>
    <div class="col-md-8 col-sm-12 mb-2">
      <div class="custom-file">
        <input type="file" class="custom-file-input" id="photo">
        <label class="custom-file-label" for="photo">Choose file</label>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <select class="custom-select">
        <option value="">Status</option>
        <option value="test">Active</option>
        <option value="test">Inactive</option>
      </select>
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <input type="text" class="form-control" placeholder="Registration Mark">
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <input type="text" class="form-control" placeholder="MOT">
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <input type="text" class="form-control" placeholder="MOT Expiry Date">
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <input type="text" class="form-control" placeholder="Make">
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <input type="text" class="form-control" placeholder="Modal">
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <input type="text" class="form-control" placeholder="Color">
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <input type="text" class="form-control" placeholder="Body Type">
    </div>

    <div class="col-12 mb-2">
      <input type="text" class="form-control" placeholder="Passenger Capacity">
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <input type="text" class="form-control" placeholder="Registered Keeper Name">
    </div>

    <div class="col-md-6 col-sm-12 mb-2">
      <input type="text" class="form-control" placeholder="Registered Keeper Address">
    </div>

    <div class="col-12 mb-2">
      <textarea class="form-control" placeholder="Notes" rows="3"></textarea>
    </div>

    <div class="col-12 my-2">
      <div class="pl-1">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitch">
          <label class="custom-control-label" for="customSwitch">
            Primary  
          </label>
        </div>
      </div>
    </div>

    <div class="col-12 my-2">
      <button type="button" class="btn btn-primary mr-2">
        Add
      </button>
    </div>

  </div><!-- .row -->
</app-modal>
