import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  services = [];

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setTitle('Settings / Services');
  }

}
