import { Component, OnInit, AfterViewInit } from '@angular/core';
import Swal from 'sweetalert2';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'bookings-add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.scss']
})
export class AddNewComponent implements OnInit, AfterViewInit {

  noteForDriver: string;

  customerRequirements: string;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    $('.datepicker').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      timePicker: true,
      autoUpdateInput: true,
      ranges: {
          'Today': [moment(), moment()],
          'Tomorrow': [moment().add(1, 'days'), moment().add(1, 'days')],
          'After Tomorrow': [moment().add(2, 'days'), moment().add(2, 'days')],
          'Next Week': [moment().add(7, 'days'), moment().add(7, 'days')],
          'Next Month': [moment().add(1, 'months'), moment().add(1, 'months')]
      },
    })
  }

  setNoteForDriver() {
    Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Note for driver',
      showCancelButton: true,
      inputValue: this.noteForDriver || ''
    })
    .then(res => {
      if (res.isConfirmed) {
        this.noteForDriver = res.value;
      }
    })
  }

  setCustomerRequirements() {
    Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Customer requirements',
      showCancelButton: true,
      inputValue: this.customerRequirements || ''
    })
    .then(res => {
      if (res.isConfirmed) {
        this.customerRequirements = res.value;
      }
    })
  }

  handleSubmit() {
    Swal.fire("Success",'',"success")
  }

}
