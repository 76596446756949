<div class="container-fluid mb-5">
  <div class="box shadow">

    <div class="filters mb-5">
      <div class="actions">
        <button class="btn">
          <i class="far fa-eye"></i>
        </button>
        <button class="btn">
          <i class="far fa-sync"></i>
        </button>
        <button class="btn">
          <i class="far fa-redo-alt"></i>
        </button>

        <a class="btn add-new" routerLink="/users/create" [queryParams]="{ role: 'admin' }">
          <i class="far fa-plus mr-2"></i>
          Add New 
        </a>
      </div>

      <div class="search-box">
        <i class="far fa-search"></i>
        <input type="text" placeholder="Search">
      </div>
    </div>

    <div class="table-responsive">
      <table class="table custom-table">
        <thead>
          <tr>
            <th scope="col" class="no-width">
              Action
            </th>
            <th scope="col" class="no-width">
              Avatar
            </th>
            <th scope="col">
              Name
              <button class="btn chevrons">
                <span>
                  <i class="fas fa-chevron-up"></i>
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </th>
            <th scope="col">
              Description
            </th>
            <th scope="col">
              Email
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="actions">
                <button class="btn">
                  <i class="far fa-eye"></i>
                </button>
                <button class="btn">
                  <i class="fas fa-caret-down"></i>
                </button>
              </div>
            </td>
            <td>
              <img src="assets/images/avatar.jpg" class="avatar">
            </td>
            <td>Alex Montilla</td>
            <td>Lorem ipsum dat san</td>
            <td>
              test@gmail.com
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>
