<div class="modal-header">
  <h4 class="modal-title pull-left">{{ heading }}</h4>
  
  <button 
    type="button" 
    class="close pull-right" 
    aria-label="Close" 
    (click)="modalRef.hide()"
  >
    <span aria-hidden="true">
      <i class="fas fa-times"></i>
    </span>
  </button>

</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>