import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit {

  zones = [];

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.setTitle('Settings / Zones');
  }

}
