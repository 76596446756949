<div class="container-fluid mb-5">
  <div class="box shadow">

    <h2 class="my-4">Customers</h2>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-1">
      <label for="checkbox-1">
        Enable Account Login
      </label>
    </div>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-2">
      <label for="checkbox-2">
        Enable New Account Registration
      </label>
    </div>

    <div class="checkbox mb-4">
      <input type="checkbox" id="checkbox-3">
      <label for="checkbox-3">
        Enable Account Activation
      </label>
    </div>

    <div class="row mb-4">
      <div class="col-md-6 col-sm-12">
        <input type="text" class="form-control mb-sm-2" placeholder="Minimum Password Length">
      </div>

      <div class="col-md-6 col-sm-12">
        <input type="text" class="form-control" placeholder="Maximum Password Length">
      </div>
    </div>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-4">
      <label for="checkbox-4">
        Enable Company Registration Number
      </label>
    </div>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-5">
      <label for="checkbox-5">
        Require Company Registration Number
      </label>
    </div>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-6">
      <label for="checkbox-6">
        Enable Company VAT Number
      </label>
    </div>

    <div class="checkbox mb-2">
      <input type="checkbox" id="checkbox-7">
      <label for="checkbox-7">
        Require Company VAT Number
      </label>
    </div>

    <div class="checkbox mb-4">
      <input type="checkbox" id="checkbox-8">
      <label for="checkbox-8">
        Show "Company Name" Next To Customer Name
      </label>
    </div>

    <div class="mb-2">
      <strong>Attach Booking Details To Customer's Booking Confirmation Notification SMS</strong>
    </div>

    <div class="mb-4">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="attach-booking" id="checkbox-9">
        <label class="form-check-label" for="checkbox-9">
          Yes
        </label>
      </div>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="attach-booking" id="checkbox-10">
        <label class="form-check-label" for="checkbox-10">
          No
        </label>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-6 col-sm-12">
        <input type="text" class="form-control" placeholder="Set Default Page After Login">
      </div>
    </div>

    <button type="button" class="btn btn-lg btn-primary">Save</button>

  </div>
</div>