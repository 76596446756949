import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-next-twenty-four-hours',
  templateUrl: './next-twenty-four-hours.component.html',
  styleUrls: ['./next-twenty-four-hours.component.scss']
})
export class NextTwentyFourHoursComponent implements OnInit {

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setTitle('Bookings / Next 24 Hours');
  }

}
