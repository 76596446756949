<form action="#" method="POST" class="rr-form">

  <div class="heading">Location:</div>

  <div class="row">
    <div class="form-group custom-input col-md-12 col-sm-12">
      <div class="outer-controls">
        <i class="fas fa-plus"></i>
      </div>
      <div class="inner-controls">
        <i class="fas fa-location-arrow"></i>
        <i class="fas fa-edit"></i>
        <i class="fas fa-arrows-alt-v"></i>
      </div>
      <input type="text" class="form-control" placeholder="Address or Passcode">
    </div>

    <div class="form-group custom-input col-md-12 col-sm-12">
      <div class="outer-controls icon-vertical">
        <i class="fas fa-exchange-alt"></i>
      </div>
      <div class="inner-controls">
        <i class="fas fa-location-arrow"></i>
        <i class="fas fa-edit"></i>
        <i class="fas fa-arrows-alt-v"></i>
      </div>
      <input type="text" class="form-control" placeholder="Address or Passcode">
    </div>

    <div class="form-group col-md-6 col-sm-12">
      <input type="text" class="form-control datepicker" placeholder="Date & Time">
    </div>

    <div class="form-group custom-input col-md-6 col-sm-12">
      <div class="outer-controls icon-vertical">
        <i class="fas fa-plane"></i>
      </div>
      <select class="custom-select">
        <option selected>Vehicle Type</option>
        <option value="saloon">Saloon</option>
        <option value="estate">Estate</option>
        <option value="executive">Executive</option>
        <option value="mpv">MPV</option>
        <option value="8-seater">8 Seater</option>
      </select>
    </div>
  </div>

  <div class="heading mt-4">Passenger:</div>
  
  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <select class="custom-select">
        <option selected>Account</option>
        <option value="1">One</option>
      </select>
    </div>

    <div class="form-group col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Passenger">
    </div>

    <div class="form-group col-md-4 col-sm-12">
      <select class="custom-select">
        <option selected>Amount</option>
        <option value="1">One</option>
      </select>
    </div>

    <div class="form-group col-md-4 col-sm-12">
      <select class="custom-select">
        <option selected>Amount</option>
        <option value="1">One</option>
      </select>
    </div>

    <div class="form-group col-md-4 col-sm-12">
      <button type="button" class="custom-button btn-block">
        Add Item
        <i class="fas fa-plus-circle ml-2"></i>
      </button>
    </div>
  </div>

  <div class="heading mt-4">Payment & Driver Order:</div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <select class="custom-select">
        <option selected>Payment Method</option>
        <option value="cash">Cash</option>
        <option value="paypal">PayPal</option>
      </select>
    </div>

    <div class="form-group col-md-6 col-sm-12">
      <input type="text" class="form-control" placeholder="Price">
    </div>

    <div class="form-group col-md-8 col-sm-12">
      <input type="text" class="form-control" placeholder="Pickup to Dropoff">
    </div>
    
    <div class="form-group col-md-4 col-sm-12">
      <button type="button" class="custom-button btn-block">
        Get Quote
      </button>
    </div>
    
    <div class="form-group col-md-6 col-sm-12">
      <select class="custom-select">
        <option selected>Assign Driver</option>
        <option value="test">Test</option>
      </select>
    </div>

    <div class="form-group col-md-6 col-sm-12 d-flex justify-content-between align-items-center">
      <button *ngIf="! noteForDriver" type="button" class="custom-button btn-block" (click)="setNoteForDriver()">
        Note for Driver <i class="fas fa-plus-circle ml-2"></i>
      </button>
      <span *ngIf="noteForDriver" class="trim-text">{{ noteForDriver }}</span>
      <button *ngIf="noteForDriver" type="button" (click)="setNoteForDriver()"><i class="fas fa-edit"></i></button>
    </div>

    <div class="form-group col-sm-12 d-flex justify-content-between align-items-center">
      <button *ngIf="! customerRequirements" type="button" class="custom-button btn-block" (click)="setCustomerRequirements()">
        Customer Requirements
        <i class="fas fa-plus-circle ml-2"></i>
      </button>
      <span *ngIf="customerRequirements" class="trim-text">{{ customerRequirements }}</span>
      <button *ngIf="customerRequirements" type="button" (click)="setCustomerRequirements()"><i class="fas fa-edit"></i></button>
    </div>

    <div class="form-group col-md-6 col-sm-12">
      <div class="total">Total: $46.6</div>
    </div>

    <div class="form-group col-md-6 col-sm-12">
      <button type="button" class="btn btn-primary btn-block" (click)="handleSubmit()">
        Save
      </button>
    </div>

  </div>

</form>
