<div class="container-fluid login">
  <div class="row">
    <div class="col-md-6 col-sm-12 logo">
      <a routerLink="">
        <img src="assets/images/logo.png" alt="Reliable Ride logo">
      </a>
    </div>

    <div class="col-md-6 col-sm-12 form">
      <h1>
        Login To <strong>Reliable Ride</strong>
      </h1>

      <form>
        <div class="form-group custom-input col-md-12 col-sm-12">
          <input type="text" class="form-control" placeholder="Username">
        </div>

        <div class="form-group custom-input col-md-12 col-sm-12">
          <input type="text" class="form-control" placeholder="Password">
        </div>

        <div class="d-flex justify-content-center align-items-center mb-5">
          <button type="submit" class="btn btn-primary">Log In</button>
          <span>
            Don't have an account? <a href="">Sign up</a>
          </span>
        </div>
      </form>
    </div>
  </div>
</div>