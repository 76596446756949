import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  badges = [
    { class: 'badge-purple',  title: 'Confirmed' },
    { class: 'badge-green',   title: 'Completed' },
    { class: 'badge-blue',    title: 'New' },
    { class: 'badge-red',     title: 'Cancelled' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
