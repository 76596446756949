<div class="container-fluid">
  <div class="box mb-5">

    <form action="#" method="POST" class="rr-form">

      <div class="row">

        <div class="form-group custom-input col-12">
          <select class="custom-select">
            <option selected>Comments</option>
            <option value="">Complaints</option>
            <option value="">Lost & Found</option>
          </select>
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12">
          <input type="text" class="form-control" placeholder="Name">
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12">
          <input type="text" class="form-control" placeholder="Email">
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12">
          <input type="text" class="form-control" placeholder="Phone">
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12">
          <input type="text" class="form-control" placeholder="Reference Number">
        </div>

        <div class="form-group custom-input col-12">
          <textarea rows="5" class="form-control" placeholder="Message"></textarea>
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12">
          <select class="custom-select">
            <option selected>Status</option>
            <option value="">Active</option>
            <option value="">Inactive</option>
          </select>
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12">
          <input type="text" class="form-control" placeholder="Order">
        </div>

        <div class="form-group custom-input col-md-6 col-sm-12 mb-5">
          <button class="btn-primary-border btn-block">
            Additional Files
            <i class="fas fa-plus-circle ml-2"></i>
          </button>
        </div>
        <div class="col-md-6 col-sm-12 mb-5"></div>
        
        <div class="col-md-3 col-sm-12 mb-2">
          <button class="btn-primary-border btn-block">Add</button>
        </div>

        <div class="col-md-3 col-sm-12 mb-2">
          <button class="btn-primary btn-block">Cancel</button>
        </div>

      </div>

    </form>

  </div><!-- .box -->

</div><!-- .container-fluid -->
