<div class="container-fluid mb-5">
  <div class="box shadow">

    <h2 class="my-4">Web Booking (Buttons and Links)</h2>

    <div class="row">
      <div class="col-md-6 col-sm-12 mb-4">
        <strong class="d-block mb-3">Default</strong>

        <div class="row">
          <div class="col-4">
            <small class="d-block mb-3">Background</small>
            <input type="color">
          </div>
          <div class="col-4">
            <small class="d-block mb-3">Border</small>
            <input type="color">
          </div>
          <div class="col-4">
            <small class="d-block mb-3">Text</small>
            <input type="color">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mb-4">
        <strong class="d-block mb-3">Active</strong>

        <div class="row">
          <div class="col-4">
            <small class="d-block mb-3">Background</small>
            <input type="color">
          </div>
          <div class="col-4">
            <small class="d-block mb-3">Border</small>
            <input type="color">
          </div>
          <div class="col-4">
            <small class="d-block mb-3">Text</small>
            <input type="color">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 mb-4">
        <strong class="d-block mb-3">Border Radius (Pixels)</strong>
        <input type="number" class="form-control" value="0">
      </div>
    </div>

    <div class="d-flex align-items-center mb-3">
      <strong class="mr-2">Custom CSS</strong>
      <i class="fas fa-info-circle mr-2"></i>
      <button class="btn btn-link" (click)="webCustomCSSToggle()">Show</button>
    </div>
    <textarea rows="4" class="form-control" *ngIf="webCustomCSS"></textarea>

    <h2 class="my-4">Mobile App (Buttons and Links)</h2>

    <div class="row">
      <div class="col-md-6 col-sm-12 mb-4">
        <strong class="d-block mb-3">Default</strong>

        <div class="row">
          <div class="col-4">
            <small class="d-block mb-3">Background</small>
            <input type="color">
          </div>
          <div class="col-4">
            <small class="d-block mb-3">Border</small>
            <input type="color">
          </div>
          <div class="col-4">
            <small class="d-block mb-3">Text</small>
            <input type="color">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mb-4">
        <strong class="d-block mb-3">Active</strong>

        <div class="row">
          <div class="col-4">
            <small class="d-block mb-3">Background</small>
            <input type="color">
          </div>
          <div class="col-4">
            <small class="d-block mb-3">Border</small>
            <input type="color">
          </div>
          <div class="col-4">
            <small class="d-block mb-3">Text</small>
            <input type="color">
          </div>
        </div>
      </div>
    </div>
    
    <div class="d-flex align-items-center mb-3">
      <strong class="mr-2">Custom CSS</strong>
      <i class="fas fa-info-circle mr-2"></i>
      <button class="btn btn-link" (click)="mobileCustomCSSToggle()">Show</button>
    </div>
    <textarea rows="4" class="form-control" *ngIf="mobileCustomCSS"></textarea>

    <h2 class="my-4">Invoice</h2>

    <div class="row">
      <div class="col-md-6 col-sm-12 mb-4">
        <strong class="d-block mb-3">Default</strong>

        <div class="row">
          <div class="col-4">
            <small class="d-block mb-3">Background</small>
            <input type="color">
          </div>
          <div class="col-4">
            <small class="d-block mb-3">Text</small>
            <input type="color">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mb-4">
        <strong class="d-block mb-3">Active</strong>

        <div class="row">
          <div class="col-4">
            <small class="d-block mb-3">Background</small>
            <input type="color">
          </div>
          <div class="col-4">
            <small class="d-block mb-3">Text</small>
            <input type="color">
          </div>
        </div>
      </div>
    </div>

    <button type="button" class="btn btn-lg btn-primary">Save</button>

  </div>
</div>
