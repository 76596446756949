<div class="container-fluid mb-5">
  <div class="box shadow">

    <div class="row rr-form">
      <div class="col-12 mb-4">
        <input type="text" class="form-control" placeholder="Name">
      </div>

      <div class="col-12 mb-4">
        <div id="map" #map></div>
      </div>

      <div class="col-12 mb-4">
        <input type="text" class="form-control" placeholder="Address">
      </div>

      <div class="col-md-6 col-sm-12 mb-4">
        <input type="text" class="form-control" placeholder="Radius">
      </div>

      <div class="col-md-6 col-sm-12 mb-4">
        <input type="text" class="form-control" placeholder="Latitude">
      </div>

      <div class="col-md-6 col-sm-12 mb-4">
        <input type="text" class="form-control" placeholder="Longitude">
      </div>

      <div class="col-md-6 col-sm-12 mb-4">
        <input type="text" class="form-control" placeholder="Order">
      </div>

      <div class="col-12 my-4">
        <div class="pl-1">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitch">
            <label class="custom-control-label" for="customSwitch">
              Airport  
            </label>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 mb-4">
        <select class="custom-select">
          <option value="">Meeting Point</option>
          <option value="">All other airports</option>
          <option value="">Gatwick North</option>
          <option value="">Gatwick North & South</option>
          <option value="">Gatwick South</option>
        </select>
      </div>

      <div class="col-md-6 col-sm-12 mb-4 d-flex align-items-center">
        <a href="#">Add new meeting point</a>
      </div>

      <div class="col-12 my-2">
        <button type="button" class="btn btn-primary mr-2">
          Add
        </button>

        <button type="button" class="btn btn-light mr-2">
          Cancel
        </button>
      </div>

    </div><!-- .row -->

  </div>
</div>
