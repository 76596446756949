<div class="box shadow mx-5 mb-5 py-5">
  <div class="container">
    <div class="row mb-5">
      <div class="col-md-3 col-sm-12">
        <img src="assets/images/avatar.jpg" class="avatar avatar-lg">
      </div>
      <div class="col-md-9 col-sm-12">
        <h3>Admin</h3>
        <p>Member Since 1 Year 4 Months</p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-4 col-sm-12">
        <strong class="ml-md-3">Role:</strong>
      </div>
      <div class="col-md-8 col-sm-12">Admin</div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4 col-sm-12">
        <strong class="ml-md-3">Username:</strong>
      </div>
      <div class="col-md-8 col-sm-12">Thomas</div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4 col-sm-12">
        <strong class="ml-md-3">Email:</strong>
      </div>
      <div class="col-md-8 col-sm-12">test@gmail.com</div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4 col-sm-12">
        <strong class="ml-md-3">Language:</strong>
      </div>
      <div class="col-md-8 col-sm-12">English</div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4 col-sm-12">
        <strong class="ml-md-3">Telephone:</strong>
      </div>
      <div class="col-md-8 col-sm-12">0333 2332332</div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4 col-sm-12">
        <strong class="ml-md-3">Status:</strong>
      </div>
      <div class="col-md-8 col-sm-12">
        <div>
          <span class="badge badge-green">Approved</span>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4 col-sm-12">
        <strong class="ml-md-3">Updated at:</strong>
      </div>
      <div class="col-md-8 col-sm-12">12/12/2020 11:00</div>
    </div>
    <div class="row mb-4">
      <div class="col-md-4 col-sm-12">
        <strong class="ml-md-3">Created at:</strong>
      </div>
      <div class="col-md-8 col-sm-12">12/12/2020 11:00</div>
    </div>
    <div class="ml-md-3">
      <button type="submit" class="btn btn-border mr-2">Edit</button>
      <button type="button" class="btn btn-primary">Back</button>
    </div>
  </div>
</div>
